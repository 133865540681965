@font-face {
  font-family: "MoscowSans";
  src: url(assets/fonts/moscowsansregular.ttf) format("truetype");
}

html,
body,
#root  {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

body {
  font-family: "MoscowSans";
  margin: 0;
  overflow: hidden;
  * {
    font-family: "MoscowSans";
  }
}

.MuiTypography-root {
  white-space: pre-line;
}
